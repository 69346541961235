<template>
    <div>
      <Header :id="id" :exhibitionId="exhibitionId"></Header>
      <div class="main">
        <div class="about">
           <div class="contact">
                <div class="tag"></div>
                <div class="us"> {{ $t('Introduction')}}</div>
            </div>
            <div class="video">
              <video :src="list.videoUrl" controls="controls" class="videoimg"></video>
            </div>
            <div class="vinfo">
              {{list.companyDesc}}
            </div>
        </div>
        <!-- <div class="contact2">
            <div class="tag"></div>
            <div class="us2">{{ $t('ContactUs') }}</div>
        </div> -->
        <div class="vbanner">
          <a-carousel class="exbimg"  arrows>
            <div
              slot="prevArrow"
              slot-scope=""
              class="custom-slick-arrow"
            >
              <a-icon type="left" />
            </div>
            <div slot="nextArrow" slot-scope="" class="custom-slick-arrow">
              <a-icon type="right" />
            </div>
            <div v-for="k in Math.ceil(qualifications.length / 5)" :key="k" class="exbimg2">
                <div v-for="(item, index) in qualifications.slice((k - 1) * 5, k * 5)" :key="index" class="exbimg3">
                  <img :src="item">
                </div>
            </div>
          </a-carousel>
        </div>
        <Contact></Contact>
      </div> 
      <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "../components/Footer";
import Contact from "./components/Contact";

export default {
  name: "SupplierAbout",
  components: {
    Footer,
    Header,
    Contact
  },
   data() {
    return {
      props: [
        require('@/assets/images/zizhi.png'),
        require('@/assets/images/zizhi.png'),
        require('@/assets/images/zizhi.png'),
        require('@/assets/images/zizhi.png'),
        require('@/assets/images/zizhi.png'),
        require('@/assets/images/zizhi.png'),
        require('@/assets/images/zizhi.png'),
        require('@/assets/images/zizhi.png'),
        require('@/assets/images/zizhi.png'),
        require('@/assets/images/zizhi.png'),
      ],
       id: this.$route.params.id,
       exhibitionId: this.$route.params.exhibitionId,
       list: [],
       qualifications: []
    }
  },
  mounted () {
    this.getConmpany();
  },
   methods: {
     // 获取公司信息
    getConmpany () {
      this.$common.fetchList('/company/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.list = result
          this.qualifications = result.qualifications
          console.log(this.list)
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
   }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/spabout.less';
</style>